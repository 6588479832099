<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getServicesRegistry.name"
                    :avatarData="getServicesRegistry.currentMenager"
                    @clearSearch="clearTable"
                />

                <Table
                    :title="getServicesRegistry.name"
                    :items="getServicesTable.items"
                    :headers="headers"
                    :length="getServicesTable.pages"
                    :page="getServicesTable.page"
                    :height="
                        !this.isTablet()
                            ? 'calc(100vh - 362px)'
                            : 'calc(100vh - 200px)'
                    "
                    @search="setSearch"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    @clear-table="clearTable"
                    :defaultFields="getServicesSearchFields.searchFields"
                    :loading="getServicesTable.loading"
                >
                    <Columns
                        slot="columns"
                        :activeColumn="activeColumn"
                        :items="getServicesTable.items"
                    />
                </Table>
                <Modal
                    :title="$t('service:additionService')"
                    :open="open"
                    :height="'850'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>

                <Modal
                    :title="'Stan na dzień'"
                    :open="openOrdersModal"
                    :fullscreen="true"
                    v-on:close="closeOrdersModal"
                >
                    <OrdersContent :refresh="refresh" slot="AEContent" />
                    <ButtonsOrders
                        slot="buttons"
                        @close-order="closeOrdersModal"
                        @closeOrdersModal="open = false"
                    />
                </Modal>
                <Modal
                    :title="$t('devices:additionDevice')"
                    :open="openAddDevice"
                    :height="
                        window.width >= 1200 ? '760' : 'calc( 600px - 110px )'
                    "
                    :width="`${window.width - 200}`"
                    v-on:close="closeModal"
                >
                    <AEContentDevice slot="AEContent" />
                    <ButtonsDevice
                        :action="'create'"
                        slot="buttons"
                        @closeModal="openAddDevice = false"
                    />
                </Modal>

                <div
                    class="d-flex justify-space-between buttons--action action--buttons"
                >
                    <ButtonsReport />
                    <ButtonsService
                        @show-orders="showOrders()"
                        @add-service="addService()"
                        @add-device="addDevice()"
                    />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from './../../../components/Registries/Service/Table/Columns'
import AEContent from './../../../components/Registries/Service/Modal/Content'
import OrdersContent from './../../../components/Registries/Service/OrdersContent/OrdersContent'
import Buttons from './../../../components/Registries/Service/Modal/Buttons'
import ButtonsOrders from './../../../components/Registries/Service/OrdersContent/ButtonsOrders'
import ButtonsService from './../../../components/Registries/Service/Table/ButtonsService'
import ButtonsReport from './../../../components/Registries/Service/Table/ButtonsReport'
import AEContentDevice from './../../../components/Registries/Devices/Modal/Content'
import ButtonsDevice from './../../../components/Registries/Devices/Modal/Buttons'
export default {
    data() {
        return {
            loading: false,
            selection: 1,
            open: false,
            openAddDevice: false,
            openOrdersModal: false,
            refresh: false,
            window: {
                width: 0,
            },
            activeColumn: false,
            headers: [
                {
                    text: this.$t('service:client'),
                    value: 'client.name',
                    width: 'auto',
                    showSearch: true,
                },
                {
                    text: this.$t('service:device'),
                    value: 'device.model.manufacturer join device.model.model',
                    width: `${this.isTablet() ? '21%' : '13.5%'}`,
                    showSearch: true,
                },
                {
                    text: this.$t('service:status'),
                    value: 'status.name',
                    width: '7%',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'circles',
                    width: '10%',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: this.$t('service:term'),
                    value: 'term',
                    width: '13%',
                    align: 'left',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: `${this.isTablet() ? '8%' : '11%'}`,
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
        ButtonsService,
        ButtonsReport,
        OrdersContent,
        ButtonsOrders,
        AEContentDevice,
        ButtonsDevice,
    },
    computed: {
        ...mapGetters([
            'getServicesTable',
            'getServicesSearchFields',
            'getServicesRegistry',
            'getProfileDetails',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchUsers',
            'fetchServiceTable',
            'fetchRegistriesTable',
            'fetchAllServices',
        ]),
        ...mapMutations([
            'clearServiceModal',
            'clearServiceErrors',
            'setServiceTable',
            'setServiceSearch',
            'clearServiceTable',
            'setServiceWhere',
            'clearDevicesModal',
        ]),

        technicianColumns() {
            const forLogisticColumns = [
                {
                    text: this.$t('service:index'),
                    value: 'index',
                    width: '8%',
                    orderInColumn: 0,
                    showSearch: true,
                },
                {
                    text: this.$t('service:createdAt'),
                    value: 'createdAt',
                    width: '7.1%',
                    orderInColumn: 1,
                },
                {
                    text: this.$t('service:technic'),
                    value: 'technician.name join technician.lastname',
                    width: '10%',
                    orderInColumn: 4,
                    showSearch: true,
                },
            ]
            if (!this.isTablet()) {
                forLogisticColumns.forEach(element => {
                    this.headers.splice(element.orderInColumn, 0, element)
                })
                this.activeColumn = true
            }
        },
        closeModal() {
            // this.clearServicesErrors()
            this.openAddDevice = false
            this.open = false
            this.clearDevicesModal()
            this.clearServiceModal()
        },
        closeOrdersModal() {
            // this.clearServicesErrors()
            this.refresh = false
            this.openOrdersModal = false
        },
        handleResize() {
            this.window.width = window.innerWidth
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        addDevice() {
            this.clearDevicesModal()
            this.openAddDevice = true
        },
        async addService() {
            this.fetchRegistriesTable({
                select: 'icon url',
                where: { url: 'devices' },
            })
            this.clearServiceErrors()
            this.clearServiceModal()
            this.open = true
        },
        async showOrders() {
            this.refresh = true
            this.openOrdersModal = true
        },
        setSearchFields(searchFields) {
            this.setServiceSearch(searchFields)
        },
        async setSearch(search) {
            this.setServiceTable({ search, page: 1 })
            if (this.getServicesTable.isArchive) {
                await this.fetchAllServices()
                return
            }
            await this.fetchServiceTable()
        },
        async setPage(page) {
            this.setServiceTable({ page })
            if (this.getServicesTable.isArchive) {
                await this.fetchAllServices()
                return
            } else {
                await this.fetchServiceTable()
            }
        },
        async setSort(sort) {
            this.setServiceTable({ sort })
            await this.fetchServiceTable()
        },
        async clearTable() {
            this.getSearch.text = ''
            this.clearServiceTable(this.getProfileDetails.specialRoleName)
            await this.fetchServiceTable()
            this.setServiceWhere({})
        },
    },

    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize()
    },
    beforeMount() {
        this.technicianColumns()
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/service/:id') {
            store.commit('clearServiceTable')
            store.dispatch('fetchServiceTable').then(() => next())
        }
        next()
    },
}
</script>
<style lang="scss">
.action--buttons {
    width: 100%;
}
</style>
