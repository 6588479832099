<template>
    <div class="buttonsContainer d-flex ml-1">
        <div v-if="showRaports()">
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="raport--btn mr-2 ml-5"
                        fab
                        v-on="on"
                        x-small
                        color="#efaa10"
                        :disabled="
                            getFilterCounter[
                                'realization.requirements.continuation'
                            ] == 0
                                ? true
                                : false
                        "
                        @click="
                            setFilter({
                                'realization.requirements.continuation': true,
                            })
                        "
                    >
                        {{ $t('service:continuations')[0] }}
                        <v-badge
                            v-if="
                                getFilterCounter[
                                    'realization.requirements.continuation'
                                ] == 0
                                    ? false
                                    : true
                            "
                            color="#19aa8d"
                            class="badge--details--buttonsReport"
                            offset-x="-5"
                            offset-y="22"
                            :content="
                                getFilterCounter[
                                    'realization.requirements.continuation'
                                ]
                            "
                        >
                        </v-badge>
                    </v-btn>
                </template>
                <span>{{ $t('service:continuations') }}</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="raport--btn mr-2"
                        fab
                        color="#009fff"
                        v-on="on"
                        x-small
                        @click="
                            setFilter({
                                'realization.requirements.invoice': true,
                            })
                        "
                        :disabled="
                            getFilterCounter[
                                'realization.requirements.invoice'
                            ] == 0
                                ? true
                                : false
                        "
                    >
                        {{ $t('service:invoices')[0] }}
                        <v-badge
                            v-if="
                                getFilterCounter[
                                    'realization.requirements.invoice'
                                ] == 0
                                    ? false
                                    : true
                            "
                            color="#19aa8d"
                            class="badge--details--buttonsReport"
                            offset-x="-5"
                            offset-y="22"
                            :content="
                                getFilterCounter[
                                    'realization.requirements.invoice'
                                ]
                            "
                        >
                        </v-badge>
                    </v-btn>
                </template>
                <span>{{ $t('service:invoices') }}</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="raport--btn mr-2"
                        fab
                        color="#e7c60b"
                        v-on="on"
                        x-small
                        @click="
                            setFilter({
                                'realization.requirements.claim': true,
                            })
                        "
                        :disabled="
                            getFilterCounter[
                                'realization.requirements.claim'
                            ] == 0
                                ? true
                                : false
                        "
                    >
                        {{ $t('service:claims')[0] }}
                        <v-badge
                            v-if="
                                getFilterCounter[
                                    'realization.requirements.claim'
                                ] == 0
                                    ? false
                                    : true
                            "
                            color="#19aa8d"
                            class="badge--details--buttonsReport"
                            offset-x="-5"
                            offset-y="22"
                            :content="
                                getFilterCounter[
                                    'realization.requirements.claim'
                                ]
                            "
                        >
                        </v-badge>
                    </v-btn>
                </template>
                <span>{{ $t('service:claims') }}</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="raport--btn mr-2"
                        fab
                        color="pink"
                        v-on="on"
                        x-small
                        @click="
                            setFilter({
                                'realization.requirements.expertise': true,
                            })
                        "
                        :disabled="
                            getFilterCounter[
                                'realization.requirements.expertise'
                            ] == 0
                                ? true
                                : false
                        "
                    >
                        {{ $t('service:expertise')[0] }}
                        <v-badge
                            v-if="
                                getFilterCounter[
                                    'realization.requirements.expertise'
                                ] == 0
                                    ? false
                                    : true
                            "
                            color="#19aa8d"
                            class="badge--details--buttonsReport"
                            offset-x="-5"
                            offset-y="22"
                            :content="
                                getFilterCounter[
                                    'realization.requirements.expertise'
                                ]
                            "
                        >
                        </v-badge>
                    </v-btn>
                </template>
                <span>{{ $t('service:expertise') }}</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="raport--btn mr-2"
                        x-small
                        fab
                        color="#0665ca"
                        v-on="on"
                        @click="
                            setFilter({
                                'realization.requirements.valuation': true,
                            })
                        "
                        :disabled="
                            getFilterCounter[
                                'realization.requirements.valuation'
                            ] == 0
                                ? true
                                : false
                        "
                    >
                        {{ $t('service:valuations')[0] }}
                        <v-badge
                            v-if="
                                getFilterCounter[
                                    'realization.requirements.valuation'
                                ] == 0
                                    ? false
                                    : true
                            "
                            color="#19aa8d"
                            class="badge--details--buttonsReport"
                            offset-x="-4"
                            offset-y="22"
                            :content="
                                getFilterCounter[
                                    'realization.requirements.valuation'
                                ]
                            "
                        >
                        </v-badge>
                    </v-btn>
                </template>
                <span>{{ $t('service:valuations') }}</span>
            </v-tooltip>
        </div>
        <div>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="raport--btn"
                        :class="showRaports() ? 'ml-0' : 'ml-5'"
                        x-small
                        fab
                        color="#0665ca"
                        v-on="on"
                        @click="showAllService()"
                    >
                        {{ $t('service:archive')[0] }}
                    </v-btn>
                </template>
                <span>Archiwalne</span>
            </v-tooltip>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['service'] },
    data() {
        return {
            continuations: 0,
        }
    },
    computed: {
        ...mapGetters(['getFilterCounter', 'getProfileDetails']),
    },
    methods: {
        ...mapActions(['fetchServiceTable', 'fetchAllServices']),
        ...mapMutations(['setServiceWhere', 'setServiceTable']),
        add() {
            return this.checkPermissions('CREATE')
        },
        async showAllService() {
            this.fetchAllServices()
        },
        async setFilter(filter) {
            let field
            if (Object.keys(filter).includes('$or')) {
                field = 'realization.requirementsCompleted.continuation'
            } else
                field = `realization.requirementsCompleted.${String(
                    Object.keys(filter)[0]
                )
                    .split('.')
                    .pop()}`

            let ne = {}
            ne[field] = false
            this.setServiceWhere({
                isDeleted: false,
                ...ne,
                ...filter,
            })
            this.setServiceTable({ page: 1 })
            await this.fetchServiceTable()
        },
        showRaports() {
            if (
                this.getProfileDetails.specialRoleName.includes('logistic') ||
                (this.getProfileDetails.specialRoleName.includes('logistic') &&
                    this.getProfileDetails.specialRoleName.includes(
                        'technic'
                    )) ||
                (this.getProfileDetails.specialRoleName.includes(
                    'logisticDirector'
                ) &&
                    this.getProfileDetails.specialRoleName.includes(
                        'technic'
                    )) ||
                this.getProfileDetails.specialRoleName.includes(
                    'logisticDirector'
                ) ||
                this.getProfileDetails.isAdmin === true ||
                this.getProfileDetails.isSuperAdmin === true
            )
                return true
            else false
        },
    },
}
</script>
<style lang="sass" scoped>
.raport--btn
    color: white
</style>
