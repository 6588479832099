<template>
    <tbody>
        <tr
            v-for="(item, index) in items"
            :key="index"
            :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
        >
            <!-- === === INDEX === === -->
            <td v-if="activeColumn" class="truncate font-12">
                {{ $get(item, 'index', '') }}
            </td>
            <!-- === === CREATED AT === === -->
            <td v-if="activeColumn" class="truncate">
                <DoubleLine
                    :firtstLine="`${$moment(item.createdAt).format(
                        `DD-MM-YYYY`
                    )}`"
                    :secondLine="`${$moment(item.createdAt).format(`HH:mm`)}`"
                />
            </td>
            <!-- === === CLIENT === === -->

            <td class="truncate" style="position: relative;">
                <v-menu absolute max-width="500" :position-x="850">
                    <template v-slot:activator="{ on: menu }">
                        <span v-on="menu" class="cursor-pointer">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        <DoubleLine
                                            :firtstLine="`${$get(
                                                item,
                                                'device.client.name',
                                                ''
                                            )}`"
                                            :secondLine="`${location(
                                                item.device
                                            )}`"
                                        />
                                    </span>
                                </template>
                                <span class="d-block"
                                    >ID:
                                    {{
                                        $get(
                                            item,
                                            'device.client.contactId',
                                            ''
                                        )
                                    }}</span
                                >

                                <span class="d-block">{{
                                    `${$t('contacts:clientGuardian')}: ${$get(
                                        item,
                                        'device.client.clientGuardian.name',
                                        ''
                                    )} ${$get(
                                        item,
                                        'device.client.clientGuardian.lastname',
                                        ''
                                    )}`
                                }}</span>
                            </v-tooltip>
                        </span>
                    </template>
                    <v-card>
                        <v-list class="pa-0">
                            <v-list-item-content class="pa-0">
                                <span class="more--title bg-grey pa-3">{{
                                    item.device.client.name
                                }}</span>
                                <span class="font-22 px-3">
                                    {{ branche(item.device).name }}
                                </span>
                                <span class="font-22 px-3"
                                    >{{ `${$t('service:str')}`
                                    }}{{ branche(item.device).address }}
                                </span>
                                <span class="font-22 px-3">
                                    {{ branche(item.device).postCode }}
                                    {{ branche(item.device).city }}</span
                                >
                                <span class="font-22 px-3 pb-2">
                                    {{ item.device.floor }}</span
                                >

                                <div
                                    class="font-22 d-flex flex-column"
                                    v-if="item.contractor"
                                >
                                    <span class="more--title pa-3 bg-grey">{{
                                        `${$t('service:contractor')}: `
                                    }}</span>
                                    <span class="mt-2 px-3 mb-5">
                                        {{ item.contractor.name }}
                                        {{ item.contractor.lastname }}
                                        <span
                                            v-if="item.contractor.phoneNumber"
                                        >
                                            • {{ `${$t('service:tel')}` }}
                                            {{
                                                $get(
                                                    item,
                                                    'contractor.phoneNumber',
                                                    'brak numeru telefonu'
                                                )
                                            }}</span
                                        ></span
                                    >
                                </div>
                            </v-list-item-content>
                        </v-list>
                    </v-card>
                </v-menu>
            </td>
            <!-- === === DEVICE === === -->
            <td class="truncate">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            <DoubleLine
                                :firtstLine="`${$get(
                                    item,
                                    'device.manufacturer',
                                    $get(item, 'device.model.manufacturer', '')
                                )} ${$get(item, 'device.model.model', '')}`"
                                :secondLine="`${$get(
                                    item,
                                    'device.model.deviceType',
                                    ''
                                )}`"
                            />
                        </span>
                    </template>
                    <span>S/N: {{ $get(item, 'device.sn', '') }}</span>
                </v-tooltip>
            </td>
            <!-- === === TECHNIC === === -->
            <td
                v-if="activeColumn"
                class="truncate font-12 cursor-pointer ma-1"
            >
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <span
                            v-on="menu"
                            @click="getServiceman()"
                            class="pointer"
                        >
                            {{
                                `${$get(
                                    item,
                                    'technician.name',
                                    '----'
                                )} ${$get(item, 'technician.lastname', '----')}`
                            }}
                        </span>
                    </template>
                    <v-list>
                        <v-list-item
                            class="cursor-pointer ma-1 serviceMan"
                            v-for="(servicemen, index) in serviceman"
                            :key="index"
                            @click="serviceEditInTable(servicemen, item._id)"
                        >
                            <v-list-item-title>
                                {{
                                    `${servicemen.name} ${servicemen.lastname}`
                                }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </td>
            <!-- === === STATUS === === -->
            <td>
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-chip
                            v-on="menu"
                            :color="
                                $get(item, 'status.color.background', '#555555')
                            "
                            :style="`color: ${$get(
                                item,
                                'status.color.font',
                                '#FFFFFF'
                            )}`"
                            dark
                            label
                            small
                            >{{
                                $get(item, 'status.name', $t('users:empty'))
                            }}</v-chip
                        >
                    </template>
                    <v-list>
                        <v-list-item
                            class="cursor-pointer ma-1 serviceMan"
                            v-for="(status, index) in filteredStatuses()"
                            :key="index"
                            @click="setStatus(status, item._id)"
                        >
                            <v-list-item-title>
                                {{ `${status.name}` }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </td>
            <!-- === === CIRCLES === === -->
            <td>
                <!-- === === WARRANTY === === -->
                <v-tooltip bottom v-if="warranty($get(item, 'device'))">
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            class="cursor-pointer ma-1"
                            v-on="on"
                            color="rgb(150, 75, 132)"
                            size="25"
                        >
                            <span class="white--text">G</span>
                        </v-avatar>
                    </template>
                    <span>{{ $t('service:isWarranty') }}</span>
                </v-tooltip>
                <!-- === === CONTINUATION === === -->
                <v-tooltip
                    bottom
                    v-if="
                        $get(item, 'realizationCheckboxes.continuation', false)
                    "
                >
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            class="cursor-pointer ma-1"
                            v-on="on"
                            :color="
                                item.status.name === 'Zamknięty'
                                    ? 'grey'
                                    : '#4caf50'
                            "
                            size="25"
                        >
                            <span class="white--text">K</span>
                        </v-avatar>
                    </template>
                    <span>{{
                        `${$t('service:continuation')} | ${$get(
                            item,
                            'continuedFrom',
                            ''
                        )}`
                    }}</span>
                </v-tooltip>

                <!-- === === INVOICE === === -->
                <v-tooltip
                    bottom
                    v-if="$get(item, 'realization.requirements.invoice', false)"
                >
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            @click="confirmAction('invoice', item)"
                            class="cursor-pointer ma-1"
                            v-on="on"
                            :color="
                                $get(
                                    item,
                                    'realization.requirementsCompleted.invoice',
                                    false
                                )
                                    ? 'grey'
                                    : '#009fff'
                            "
                            size="25"
                        >
                            <span class="white--text">F</span>
                        </v-avatar>
                    </template>
                    <span>{{ $t('service:invoice') }}</span>
                </v-tooltip>
                <!-- === === CLAIM === === -->
                <v-tooltip
                    bottom
                    v-if="$get(item, 'realization.requirements.claim', false)"
                >
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            class="cursor-pointer ma-1"
                            v-on="on"
                            @click="confirmAction('claim', item)"
                            :color="
                                $get(
                                    item,
                                    'realization.requirementsCompleted.claim',
                                    false
                                )
                                    ? 'grey'
                                    : '#e8c70c'
                            "
                            size="25"
                        >
                            <span class="white--text">C</span>
                        </v-avatar>
                    </template>
                    <span>{{ $t('service:requiredClaim') }}</span>
                </v-tooltip>
                <!-- === === VALUATION === === -->
                <v-tooltip
                    bottom
                    v-if="
                        $get(item, 'realization.requirements.valuation', false)
                    "
                >
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            class="cursor-pointer ma-1"
                            v-on="on"
                            :color="
                                $get(
                                    item,
                                    'realization.requirementsCompleted.valuation',
                                    false
                                )
                                    ? 'grey'
                                    : '#0665ca'
                            "
                            size="25"
                        >
                            <span class="white--text">W</span>
                        </v-avatar>
                    </template>
                    <span>
                        {{
                            $get(
                                item,
                                'estimates.status.name',
                                'Brak ustalonego statusu'
                            )
                        }}</span
                    >
                </v-tooltip>
                <!-- === === EXPERTISE === === -->
                <v-tooltip
                    bottom
                    v-if="
                        $get(item, 'realization.requirements.expertise', false)
                    "
                >
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            class="cursor-pointer ma-1"
                            v-on="on"
                            @click="confirmAction('expertise', item)"
                            :color="
                                $get(
                                    item,
                                    'realization.requirementsCompleted.expertise',
                                    false
                                )
                                    ? 'grey'
                                    : 'pink'
                            "
                            size="25"
                        >
                            <span class="white--text">E</span>
                        </v-avatar>
                    </template>
                    <span>{{ $t('service:expertise') }}</span>
                </v-tooltip>
                <!-- === === REQUIRED CONTINUATION === === -->
                <v-tooltip
                    bottom
                    v-if="
                        $get(
                            item,
                            'realization.requirements.continuation',
                            false
                        )
                    "
                >
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            class="cursor-pointer ma-1"
                            v-on="on"
                            @click="confirmAction('continuation', item)"
                            :color="
                                $get(
                                    item,
                                    'realization.requirementsCompleted.continuation',
                                    false
                                )
                                    ? 'grey'
                                    : '#efaa10'
                            "
                            size="25"
                        >
                            <span class="white--text">K</span>
                        </v-avatar>
                    </template>
                    <span>{{ $t('service:requiredContinuation') }}</span>
                </v-tooltip>
                <!-- === === URGENT === === -->
                <v-tooltip
                    bottom
                    v-if="$get(item, 'realizationCheckboxes.urgent', false)"
                >
                    <template v-slot:activator="{ on }">
                        <v-avatar
                            class="cursor-pointer ma-1"
                            v-on="on"
                            color="red"
                            size="25"
                        >
                            <span class="white--text">P</span>
                        </v-avatar>
                    </template>
                    <span>{{ $t('service:urgent') }}</span>
                </v-tooltip>
            </td>
            <!-- === === TERM === === -->
            <td class="truncate font-12">
                <span class="d-flex flex-column">
                    <span>
                        <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-avatar
                                    v-on="on"
                                    class="cursor-pointer"
                                    color="orange"
                                    size="15"
                                >
                                    <span class="white--text">P</span>
                                </v-avatar>
                                <span v-on="on" class="cursor-pointer">
                                    {{
                                        $get(item, 'plannedServiceDate', null)
                                            ? $moment(
                                                  $get(
                                                      item,
                                                      'plannedServiceDate',
                                                      null
                                                  )
                                              ).format('DD-MM-YYYY')
                                            : '------'
                                    }}
                                </span>
                            </template>
                            <v-date-picker
                                first-day-of-week="1"
                                v-model="termsDate"
                                @input="serviceEditDate(termsDate, item._id)"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <span
                                    v-on="on"
                                    :style="`background: ${
                                        item.plannedServiceTime
                                            ? '#E8D420'
                                            : 'white'
                                    }`"
                                    class="cursor-pointer"
                                >
                                    {{
                                        $get(item, 'plannedServiceTime', null)
                                            ? $get(
                                                  item,
                                                  'plannedServiceTime',
                                                  null
                                              )
                                            : '------'
                                    }}
                                </span>
                            </template>
                            <v-time-picker
                                v-model="termsTime"
                                full-width
                                format="24hr"
                                @click:minute="
                                    serviceEditTime(termsTime, item._id)
                                "
                            ></v-time-picker>
                        </v-menu>
                    </span>
                    <span>
                        <v-avatar class="cursor-pointer" color="red" size="15">
                            <span class="white--text">
                                <v-icon size="15" color="white"
                                    >mdi-square-medium</v-icon
                                >
                            </span>
                        </v-avatar>
                        {{
                            $get(item, 'realization.endDate')
                                ? `${$moment(
                                      $get(item, 'realization.endDate')
                                  ).format(`DD-MM-YYYY`)}`
                                : '------'
                        }}
                        {{ $get(item, 'realization.endTime', '------') }}
                    </span>
                </span>
            </td>
            <td style="text-align: end;">
                <v-icon small v-if="hasFile(item.folders)"
                    >mdi-paperclip</v-icon
                >
                <v-menu bottom v-if="remove()">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon color="iconGray" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>
                    <v-list dense>
                        <!-- === === REMOVE === === -->
                        <v-list-item @click="openConfirmModal(item._id)">
                            <v-list-item-icon class="mr-0">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Usuń</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn
                    @click="clickNextPageLoading(item)"
                    v-if="!item.checkIsLoading"
                    small
                    router
                    :to="`/service/${item._id}`"
                    icon
                >
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>

                <Spinner v-if="item.checkIsLoading" />
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main">
                <ConfirmContent
                    :type="type"
                    :error="this.confirmModalData.error"
                    @confirm-number="confirmData"
                    @confirm-date="confirmDate"
                />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:no') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="save()"
                        >{{ $t('global:yes') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
        <ConfirmModal
            :openConfirm="openRemoveConfirm"
            @close-confirm="closeRemovedmModal"
        >
            <div slot="main">
                <ConfirmRemoveContent />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeRemovedmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="deleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
        <ConfirmModal
            :width="'700'"
            :openConfirm="openConfirmSelectedClosed"
            @close-confirm="closeConfirmModalSelectedClosed"
        >
            <div slot="main">
                <SelectedStatusClosedConfirm :clientEmail="getClientEmail()" />
            </div>
            <div slot="buttons">
                <OnStatusClosedButtons
                    :serviceDetails="this.selectedService"
                    @close-confirm="closeConfirmModalSelectedClosed"
                />
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import ConfirmRemoveContent from '../../Departments/ConfirmModal/ConfirmContent.vue'
import ConfirmContent from './../../Service/Table/ConfirmModal'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import SelectedStatusClosedConfirm from '../../../../views/Registries/Service/SelectedStatusClosedConfirm.vue'
import OnStatusClosedButtons from '../../Service/Details/OnStatusClosed/Buttons.vue'
import Spinner from '../../../Global/Table/Spinner.vue'
export default {
    props: ['items', 'activeColumn'],
    data() {
        return {
            termsDate: '',
            openConfirm: false,
            termsTime: '',
            type: '',
            serviceman: [],
            confirmModalData: {
                text: '',
                date: '',
                error: '',
            },
            openRemoveConfirm: false,
            removeItemID: '',
            openConfirmSelectedClosed: false,
            selectedService: null,
        }
    },
    components: {
        ConfirmContent,
        ConfirmRemoveContent,
        SelectedStatusClosedConfirm,
        OnStatusClosedButtons,
        Spinner,
    },
    methods: {
        ...mapActions([
            'updateService',
            'countServiceFilters',
            'fetchServiceTable',
            'deleteService',
        ]),
        ...mapMutations(['setServiceModal', 'setSnackbars']),
        clickNextPageLoading(item) {
            item.checkIsLoading = true
        },
        async serviceEditInTable(technician, id) {
            let item = this.items.find(item => item._id === id)
            technician._id
                ? (item.technician = technician)
                : (item.technician = null)
            this.setServiceModal(item)
            let updated = await this.updateService({ id: id })
            item.status = updated.status
        },
        async serviceEditDate(date, id) {
            let item = this.items.find(item => item._id === id)
            item.plannedServiceDate = date
            this.setServiceModal(item)
            let updated = await this.updateService({ id: id })
            item.status = updated.status
        },
        async getServiceman() {
            let response = await new this.Rest('GET', '/users')
                .where({ specialRoleName: 'technic' })
                .select('name lastname _id position')
                .send()
            this.serviceman = response.data.items
            this.serviceman.push({
                name: this.$t('global:empty'),
                lastname: '',
            })
        },

        async setStatus(status, id) {
            let item = this.items.find(item => item._id === id)
            this.selectedService = cloneDeep(item)
            this.selectedService.client = item.device.client

            // When the user changes the same status to the same status
            if (item.status.name === status.name) {
                return
            }

            const prevStatus = cloneDeep(item.status)
            const nextStatus = status.name

            if (
                nextStatus === 'Zamknięty' &&
                prevStatus.name !== 'Zakończony'
            ) {
                item.status = prevStatus
                this.setSnackbars({
                    type: 'warning',
                    text: `Wymagany status Zakończony `,
                })

                return
            }

            if (
                nextStatus === 'Zatwierdzony' &&
                prevStatus.name !== 'Zamknięty'
            ) {
                this.setSnackbars({
                    type: 'warning',
                    text: `Wymagany status Zamknięty `,
                })
                item.status = prevStatus
                return
            }

            // 27.10.2022 Dodanie możliwości ustawienia statusu Anulowany od razu. Bez wymaganych poprzednich statusów.

            // if (
            //     nextStatus === 'Anulowany' &&
            //     (prevStatus.name !== 'Otwarty' ||
            //         prevStatus.name === 'Przypisany')
            // ) {
            //     this.setSnackbars({
            //         type: 'warning',
            //         text: `Wymagany status Otwarty lub Przypisany `,
            //     })
            //     item.status = prevStatus
            //     return
            // }

            if (nextStatus === 'Zamknięty') {
                this.openConfirmSelectedClosed = true
            }

            item.status = status
            try {
                this.setServiceModal(item)
                await this.updateService({ id: id })
                // this.refreshDataForTaskDriver()
            } catch (e) {
                console.log(e)
            }
        },
        async serviceEditTime(time, id) {
            let item = this.items.find(item => item._id === id)
            item.plannedServiceTime = time
            this.setServiceModal(item)
            let updated = await this.updateService({ id: id })
            item.status = updated.status
        },

        async deleteItem() {
            let result = await this.deleteService({ id: this.removeItemID })
            if (result) this.openRemoveConfirm = false
        },

        async refreshDataForTaskDriver() {
            await this.$root.$emit('onServiceAdd')
        },

        async save() {
            // === Dane do zapisania ===
            // W przypadku claima i faktury zapisujemy dane z pol, reszta przypadków
            // to tylko potwierdzenie że np. wycena została zrealizowana
            let item = this.items.find(
                item => item._id === this.editingItem._id
            )

            // set(item, `realization.requirementsCompleted[${this.type}]`, true)
            item.realization.requirementsCompleted[this.type] = true
            if (!item.realization.requirementsCompleted)
                item.realization.requirementsCompleted = {}
            if (this.type === 'invoice') {
                // item.invoiceNumber = this.confirmModalData.text
                // item.invoiceDate = this.confirmModalData.date
                // item.realization.invoiceDate = this.confirmModalData.date
                if (this.confirmModalData.text == '') {
                    item.realization.requirementsCompleted[this.type] = false
                    return (this.confirmModalData.error = 'Podaj numer faktury')
                } else {
                    item.invoiceNumber = this.confirmModalData.text
                    item.invoiceDate = this.confirmModalData.date
                }
            }
            if (this.type === 'claim')
                if (this.confirmModalData.text == '') {
                    item.realization.requirementsCompleted[this.type] = false
                    return (this.confirmModalData.error = 'Podaj numer claima')
                } else {
                    item.claimNumber = this.confirmModalData.text
                }
            this.setServiceModal(item)
            if (this.type === 'continuation') {
                await new this.Rest(
                    'POST',
                    `service/${item._id}/continue`
                ).send()
            } else {
                await this.updateService({ id: item._id })
            }
            this.countServiceFilters()
            if (this.type === 'continuation') await this.fetchServiceTable()
            // === Funkcja do zamykania i czyszczenia modala ===
            this.closeConfirmModal()
        },

        getClientEmail() {
            return this.selectedService?.client?.email || ''
        },
        closeConfirmModalSelectedClosed() {
            this.openConfirmSelectedClosed = false
        },
        closeConfirmModal() {
            this.openConfirm = false
            this.$root.$emit('clearModal')
        },
        confirmAction(type, item) {
            if (
                item.realization.requirementsCompleted &&
                item.realization.requirementsCompleted[type]
            )
                return
            this.editingItem = item
            this.type = type
            this.openConfirm = true
        },
        confirmData(val) {
            this.confirmModalData.text = val
        },
        confirmDate(val) {
            this.confirmModalData.date = val
        },

        location(val) {
            if (val.client.branches) {
                const branch = val.client.branches.find(
                    el => el._id === val.branch
                )
                if (branch)
                    return `${branch.name} • ${branch.city} • ${this.$t(
                        'service:str'
                    )} ${branch.address} `
                else return ''
            }
        },

        filteredStatuses() {
            if (
                this.getProfileDetails.isAdmin ||
                this.getProfileDetails.isSuperAdmin
            )
                return this.getServiceStatuses
            if (this.getProfileDetails.specialRoleName.includes('technic'))
                return this.getServiceStatuses.filter(
                    status => status.name === 'Zamknięty'
                )
            if (
                this.getProfileDetails.specialRoleName.includes('logistic') ||
                this.getProfileDetails.specialRoleName.includes(
                    'logisticDirector'
                )
            )
                return this.getServiceStatuses.filter(
                    status =>
                        status.name === 'Zamknięty' ||
                        status.name === 'Zatwierdzony' ||
                        status.name === 'Anulowany'
                )
            return []
        },
        warranty(item) {
            return (
                (item.guaranteeTo &&
                    this.$moment(item.guaranteeTo).isSameOrAfter(
                        new Date(),
                        'days'
                    )) ||
                (item.partGuarantee &&
                    this.$moment(item.partGuarantee).isSameOrAfter(
                        new Date(),
                        'days'
                    ))
            )
        },
        openConfirmModal(id) {
            this.removeItemID = id
            this.openRemoveConfirm = true
        },
        closeRemovedmModal() {
            this.openRemoveConfirm = false
        },
        closeConfirmModal() {
            this.openConfirm = false
        },

        remove() {
            return this.checkPermissions('DELETE')
        },
        branche(val) {
            if (val.client.branches) {
                const branch = val.client.branches.find(
                    el => el._id === val.branch
                )
                return branch ? branch : ''
            }
        },
    },
    computed: {
        ...mapGetters(['getServiceStatuses', 'getProfileDetails']),
    },
}
</script>

<style lang="sass" scoped>
.create-loading
    height: 30% !important

.more--title
    font-weight: 700
    line-height: 1.3
    font-size: 22px
.bg-grey
    background-color: #f2f2f2
.font-22
    margin-top: 5px
    font-size: 22px
.font-12
    font-size: 12px !important
.serviceMan:hover
    background-color: #f2f2f2 !important
</style>
