<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" sm="12" class="px-5">
                <v-row>
                    <v-col cols="3" class="d-flex justify-center">
                        <v-menu
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            v-model="datePicker"
                            ><template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    @click:clear="date = null"
                                    :label="`${$t('service:selectDate')}*`"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                first-day-of-week="1"
                                v-model="date"
                                @change="fetchServices"
                                @input="data1 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="4" offset="1" class="d-flex justify-center">
                        <span class="ordersDay">
                            {{
                                date
                                    ? $moment(date).format(`dddd DD-MM-YYYY `)
                                    : $moment(new Date()).format(
                                          `dddd DD-MM-YYYY `
                                      )
                            }}
                        </span>
                    </v-col>
                </v-row>
                <v-row class="orders--content">
                    <v-col
                        cols="2"
                        v-for="(item, prop) in items"
                        :key="prop"
                        md="2"
                        class="px-2 d-flex align-center flex-column ordersContent"
                    >
                        <div>
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-avatar
                                        size="40"
                                        dark
                                        v-on="{ ...tooltip }"
                                        class="cursor-pointer my-4"
                                        color="teal"
                                    >
                                        <img
                                            v-if="
                                                item[0].technician.picture &&
                                                item[0].technician.picture != ''
                                            "
                                            :src="
                                                UserPicture(
                                                    item[0].technician.picture
                                                )
                                            "
                                            alt="avatar"
                                        />
                                        <span
                                            v-else
                                            class="white--text caption"
                                            >{{
                                                `${item[0].technician.name[0]}${item[0].technician.lastname[0]}`
                                            }}</span
                                        >
                                    </v-avatar>
                                    <span class="ml-1">{{
                                        item[0].technician.name +
                                        ' ' +
                                        item[0].technician.lastname
                                    }}</span>
                                </template>
                                <span>{{ $t('service:technic') }}</span>
                            </v-tooltip>
                        </div>

                        <v-tooltip
                            top
                            v-for="(order, index) in item"
                            :key="index"
                        >
                            <template v-slot:activator="{ on: tooltip }">
                                <v-alert
                                    v-on="{ ...tooltip }"
                                    border="left"
                                    class="d-flex flex-column alertCard cursor-pointer"
                                    :color="order.status.color.background"
                                    colored-border
                                    elevation="2"
                                    @click="goToService(order._id)"
                                >
                                    <span
                                        class="alertCard__open"
                                        @click="openInAdditionalWindow"
                                        ><router-link
                                            style="text-decoration: none;"
                                            :to="{
                                                path: '/service/' + order._id,
                                            }"
                                            target="_blank"
                                            ><v-icon small
                                                >mdi-arrow-right-thick</v-icon
                                            >
                                        </router-link></span
                                    >

                                    <span class="alertCard__title">{{
                                        order.index
                                    }}</span>
                                    <span class="alertCard__title">{{
                                        `${order.device.model.manufacturer} ${order.device.model.model}`
                                    }}</span>
                                    <span class="alertCard__client">{{
                                        order.device.client.name
                                    }}</span>
                                    <span class="alertCard__client">{{
                                        getAddress(order.device)
                                    }}</span>
                                </v-alert>
                            </template>
                            <span>{{
                                `${$t('service:status')}: ${order.status.name}`
                            }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: {
        /**
         * Technic Orders
         */
        // items: {
        //     type: Array,
        // },
        refresh: {
            type: Boolean,
        },
        path: `${process.env.VUE_APP_SERVER}/secured/profile/`,
    },
    data() {
        return {
            datePicker: '',
            date: this.$moment().format('YYYY-MM-DD').toString(),
            dateType: 'plannedServiceDate',
            items: [],
        }
    },
    watch: {
        refresh: function (newVal, oldVal) {
            if (newVal === true) this.fetchServices()
        },
    },
    methods: {
        UserPicture(payload) {
            let picture = `${process.env.VUE_APP_SERVER}/secured/profile/${payload}`
            return picture
        },
        getAddress(device) {
            let b = device.client.branches.find(
                branch => branch._id === device.branch
            )
            let address = ''
            return `${b.address} ${b.city} ${b.postCode}`
        },
        goToService(id) {
            this.$router.push(`service/${id}`)
        },
        async fetchServices() {
            let where = {}
            where[this.dateType] = {
                $gte: new Date(new Date(this.date).setHours(0, 0, 0)),
                $lt: new Date(new Date(this.date).setHours(23, 59, 59)),
            }
            let response = await new this.Rest('GET', 'service')
                .where(where)
                .populate([
                    {
                        localField: 'technician',
                        foreignField: '_id',
                        from: 'users',
                        as: 'technician',
                    },
                    {
                        localField: 'device',
                        foreignField: '_id',
                        from: 'devices',
                        as: 'device',
                    },
                    {
                        localField: 'device.model',
                        foreignField: '_id',
                        from: 'models',
                        as: 'device.model',
                    },
                    {
                        localField: 'device.client',
                        foreignField: '_id',
                        from: 'contacts',
                        as: 'device.client',
                    },
                ])
                .send()
            this.items = response.data.items.reduce((acc, item) => {
                let key = item.technician && item.technician._id
                if (key) {
                    if (!acc[key]) {
                        acc[key] = []
                    }
                    acc[key].push(item)
                }
                return acc
            }, {})
        },
        openInAdditionalWindow(event) {
            event.stopPropagation()
        },
    },
    async mounted() {
        await this.fetchServices()
    },
}
</script>

<style lang="sass" scoped>
.ordersDay
    font-size: 18px
    color: green
    font-weight: 700
.ordersContent
    background-color: #f2f2f2
    min-height: 550px
    border: 5px solid white
    box-sizing: border-box
.orders--content
    height: calc(100vh - 230px) !important
    overflow-y: scroll !important
.alertCard
    width: 100%
    &__client
        font-size: 12px
        display: block
    &__title
        font-size: 12px
        font-weight: 700
        display: block
    &__open
        display: block
        float: right
</style>
