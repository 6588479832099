<template>
    <div class="d-flex align-center serviceButton">
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-speed-dial
                    v-model="iconFilter"
                    :direction="direction"
                    :transition="transition"
                >
                    <template v-slot:activator>
                        <v-btn
                            fab
                            dark
                            v-model="iconFilter"
                            v-on="on"
                            color="#7ad0bf"
                            x-small
                            v-if="filterPermission()"
                            class="mr-2"
                        >
                            <v-icon v-if="iconFilter">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-filter-plus
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                class="font--circle"
                                fab
                                dark
                                x-small
                                color="#43ea92"
                                @click="setIconFilter({})"
                            >
                                {{ $t('service:clear')[0] }}
                            </v-btn>
                        </template>
                        <span>{{ $t('service:clear') }}</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                class="font--circle"
                                fab
                                dark
                                x-small
                                color="#0665ca"
                                @click="
                                    setIconFilter({
                                        'realization.requirements.valuation': true,
                                    })
                                "
                            >
                                {{ $t('service:valuation')[0] }}
                            </v-btn>
                        </template>
                        <span>{{ $t('service:valuation') }}</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                class="font--circle"
                                fab
                                dark
                                x-small
                                color="#e7c60b"
                                @click="
                                    setIconFilter({
                                        'realization.requirements.claim': true,
                                    })
                                "
                            >
                                {{ $t('service:claim')[0] }}
                            </v-btn>
                        </template>
                        <span>{{ $t('service:claim') }}</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                class="font--circle"
                                fab
                                dark
                                x-small
                                color="#009fff"
                                @click="
                                    setIconFilter({
                                        'realization.requirements.invoice': true,
                                    })
                                "
                            >
                                {{ $t('service:invoice')[0] }}
                            </v-btn>
                        </template>
                        <span>{{ $t('service:invoice') }}</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                class="font--circle"
                                fab
                                dark
                                x-small
                                color="#964b84"
                                @click="
                                    setIconFilter({
                                        'device.guaranteeFrom': {
                                            $lte: new Date(),
                                        },
                                        'device.guaranteeTo': {
                                            $gte: new Date(),
                                        },
                                    })
                                "
                            >
                                {{ $t('service:isWarranty')[0] }}
                            </v-btn>
                        </template>
                        <span>{{ $t('service:isWarranty') }}</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                class="font--circle"
                                fab
                                dark
                                x-small
                                color="#f44336"
                                @click="
                                    setIconFilter({
                                        'realizationCheckboxes.urgent': true,
                                    })
                                "
                            >
                                {{ $t('service:urgent')[0] }}
                            </v-btn>
                        </template>
                        <span>{{ $t('service:urgent') }}</span>
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                class="font--circle"
                                fab
                                dark
                                x-small
                                color="#4caf50"
                                @click="
                                    setIconFilter({
                                        'realizationCheckboxes.continuation': true,
                                    })
                                "
                            >
                                {{ $t('service:isContinuation')[0] }}
                            </v-btn>
                        </template>
                        <span>{{ $t('service:isContinuation') }}</span>
                    </v-tooltip>
                </v-speed-dial>
            </template>
            <span>{{ $t('service:filterIcon') }}</span>
        </v-tooltip>

        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-speed-dial
                    v-model="statusFilter"
                    :direction="direction"
                    :transition="transition"
                >
                    <template v-slot:activator>
                        <v-btn
                            fab
                            dark
                            v-on="on"
                            color="#7ad0bf"
                            x-small
                            v-if="filterPermission()"
                            class="mr-2"
                        >
                            <v-icon v-if="statusFilter">
                                mdi-close
                            </v-icon>
                            <v-icon v-else small>mdi-filter</v-icon>
                        </v-btn>
                    </template>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                class="font--circle"
                                fab
                                dark
                                x-small
                                color="#19aa8d"
                                @click="setFilter()"
                            >
                                <v-icon size="15">mdi-wrench</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('service:allOfThem') }}</span>
                    </v-tooltip>
                    <v-tooltip
                        left
                        v-for="filter in getServiceStatuses"
                        :key="filter.name"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                class="font--circle"
                                fab
                                dark
                                x-small
                                :color="filter.color.background"
                                @click="setFilter(filter.name)"
                            >
                                {{ filter.name[0] }}
                            </v-btn>
                        </template>
                        <span>{{ filter.name }}</span>
                    </v-tooltip>
                </v-speed-dial>
            </template>
            <span>{{ $t('service:filter') }}</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    color="#7ad0bf"
                    dark
                    v-on="on"
                    @click="$emit('show-orders')"
                    x-small
                    class="mr-2"
                >
                    <v-icon small>mdi-format-list-checks</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('service:todayOrders') }}</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    color="#7ad0bf"
                    v-on="on"
                    small
                    v-if="add()"
                    @click="$emit('add-device')"
                    class="mr-2"
                >
                    <!-- <v-icon small>{{ addDeviceIcon }}</v-icon> -->
                    <v-icon small>mdi-printer</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('devices:addDevices') }}</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    data-cy="addEntry"
                    fab
                    dark
                    v-on="on"
                    small
                    v-if="add()"
                    @click="$emit('add-service')"
                    class="buttons--add"
                >
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('service:addService') }}</span>
        </v-tooltip>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['service'] },
    data() {
        return {
            statusFilter: false,
            iconFilter: false,
            direction: 'top',
            addDeviceIcon: 'mdi-plus',
            transition: 'slide-y-reverse-transition',
            // statusFilters: [
            //     new this.Filter('#6b926f', 'opened'),
            //     new this.Filter('#beb13a', 'started'),
            //     new this.Filter('#b14040', 'completed'),
            //     new this.Filter('#555555', 'closed'),
            //     new this.Filter('#dfafde', 'assigned'),
            //     new this.Filter('#7a7a7a', 'cancelled'),
            //     new this.Filter('#484086', 'approved'),
            // ],
            filters: [],
        }
    },
    methods: {
        ...mapActions(['fetchServiceTable', 'fetchDevicesRegistry']),
        ...mapMutations(['setServiceWhere']),
        add() {
            return this.checkPermissions('CREATE')
        },
        filterPermission() {
            return this.checkPermissions('READ')
        },
        Filter(color, value) {
            this.color = color
            this.value = value
        },
        async setFilter(filter) {
            if (!filter) {
                this.setServiceWhere({
                    isDeleted: false,
                    'technician._id': { $ne: null },
                })
            } else {
                this.setServiceWhere({
                    isDeleted: false,
                    'status.name': filter,
                })
            }
            await this.fetchServiceTable()
        },
        async setIconFilter(filter) {
            this.setServiceWhere({
                isDeleted: false,
                ...filter,
            })
            await this.fetchServiceTable()
        },
    },
    computed: {
        ...mapGetters([
            'getServiceStatuses',
            'getProfileDetails',
            'getDevicesRegistry',
        ]),
    },
    async beforeMount() {
        await this.fetchDevicesRegistry()
        this.addDeviceIcon = this.getDevicesRegistry?.icon
    },
}
</script>
<style lang="sass" scoped>
.serviceButton
    margin-bottom: 6px
.font--circle
    font-size: 13px

@media (max-width: 1100px)
    .serviceButton
        margin-bottom: 0px
</style>
