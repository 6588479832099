var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"px-5",attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":`${_vm.$t('service:selectDate')}*`,"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.date = null}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"change":_vm.fetchServices,"input":function($event){_vm.data1 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","offset":"1"}},[_c('span',{staticClass:"ordersDay"},[_vm._v(" "+_vm._s(_vm.date ? _vm.$moment(_vm.date).format(`dddd DD-MM-YYYY `) : _vm.$moment(new Date()).format( `dddd DD-MM-YYYY ` ))+" ")])])],1),_c('v-row',{staticClass:"orders--content"},_vm._l((_vm.items),function(item,prop){return _c('v-col',{key:prop,staticClass:"px-2 d-flex align-center flex-column ordersContent",attrs:{"cols":"2","md":"2"}},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-avatar',_vm._g({staticClass:"cursor-pointer my-4",attrs:{"size":"40","dark":"","color":"teal"}},{ ...tooltip }),[(
                                            item[0].technician.picture &&
                                            item[0].technician.picture != ''
                                        )?_c('img',{attrs:{"src":_vm.UserPicture(
                                                item[0].technician.picture
                                            ),"alt":"avatar"}}):_c('span',{staticClass:"white--text caption"},[_vm._v(_vm._s(`${item[0].technician.name[0]}${item[0].technician.lastname[0]}`))])]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(item[0].technician.name + ' ' + item[0].technician.lastname))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('service:technic')))])])],1),_vm._l((item),function(order,index){return _c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-alert',_vm._g({staticClass:"d-flex flex-column alertCard cursor-pointer",attrs:{"border":"left","color":order.status.color.background,"colored-border":"","elevation":"2"},on:{"click":function($event){return _vm.goToService(order._id)}}},{ ...tooltip }),[_c('span',{staticClass:"alertCard__open",on:{"click":_vm.openInAdditionalWindow}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
                                            path: '/service/' + order._id,
                                        },"target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right-thick")])],1)],1),_c('span',{staticClass:"alertCard__title"},[_vm._v(_vm._s(order.index))]),_c('span',{staticClass:"alertCard__title"},[_vm._v(_vm._s(`${order.device.model.manufacturer} ${order.device.model.model}`))]),_c('span',{staticClass:"alertCard__client"},[_vm._v(_vm._s(order.device.client.name))]),_c('span',{staticClass:"alertCard__client"},[_vm._v(_vm._s(_vm.getAddress(order.device)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(`${_vm.$t('service:status')}: ${order.status.name}`))])])})],2)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }